import axios from "axios";
import store from "../store";
import { logout } from "./account.service";

export const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export const apiBaseUrl = `${baseUrl}/api`;

export let accessToken = null;
export function setAccessToken(value) {
  accessToken = value;
}

export const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  if (
    config.data instanceof FormData &&
    config.headers["Content-Type"] == "application/json"
  ) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const authenticationStatusCodes = new Set([401, 403]);

    const loginUrl = "/user/token/";
    const refreshTokenUrl = "/user/refresh-token/";

    const authenticationUrls = new Set([loginUrl, refreshTokenUrl]);

    const { config, response: { status } } = error;

    if (
      !authenticationStatusCodes.has(status) ||
      authenticationUrls.has(config.url) ||
      store.getters.isLoggedIn === false
    ) {
      return Promise.reject(error);
    }
    

    try {
      const { data: { access } } = await axiosInstance.post(refreshTokenUrl);
      accessToken = access;
    } catch {
      await logout();

      return Promise.reject(error);
    }

    const response = await axiosInstance(config);

    return Promise.resolve(response);
  },
);

/**
 
@deprecated use axiosInstance directly*/
export default function axiosRequest(_contentType = "application/json") {
  return axiosInstance;
}