import router from "@/router/index.js";

/**
 * gotoPage
 * @param {string} page
 * @param {string} target
 * @param {object} datas
 * @return {void}
 */
const gotoPage = (page, target, datas) => {
  //  if target = "FT" open url in a new tab
  if (target == "FT") {
    window.open(page, "_blank");
    return;
  }

  //  go back one page
  if (page === "-1") {
    router.go(-1);
    return;
  }

  //  if route was given with target "blank"
  if (target == "blank") {
    const routeName = page.split("/");
    let routeData;

    //  if datas was given, can use this
    if (datas)
      routeData = router.resolve({ name: `${routeName[1]}`, query: datas });
    else routeData = router.resolve({ name: `${routeName[1]}` });

    //  open route in a new tab
    window.open(routeData.href, "_blank");
  }

  //  if only route was given
  else {
    router.push(page).then(() => {
      // Scroll en haut après la navigation vers l'accueil
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }
};

export default gotoPage;
